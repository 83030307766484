import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: "nge-overlay",
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
    onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 32 /* HYDRATE_EVENTS */))
}