import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "nge-overlay-blocker",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.$emit('hide')), ["stop"])),
    onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createVNode($setup["Overlay"], { class: "modal overlay-content" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3 /* FORWARDED */
    })
  ], 32 /* HYDRATE_EVENTS */))
}